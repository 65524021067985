import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Select from "react-select";
import Swal from "sweetalert";
import ImageUploader from "react-images-upload";
import { addInvoice, getCustomer, uploadImageApi, handleAddInputInvoiceArray, handleRemoveInputInvoiceArray, setAddInvoiceData, resetReducer } from "./InvoiceDetails.actions";


const AddInvoice = (props) => {
  const [customer, setCustomer] = useState();
  const [Date, setDate] = useState();
  const [addDesc, setAddDesc] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [packages, setPackages] = useState(1);
  const [inputField, setInputField] = useState([{
    description: "",
    width: 0,
    length: 0,
    footage: 0,
    quantity: 0,
    totalFootage: 0,
    rate: 0,
    TotalPrice: 0,
  }]);

  const getCustomer = props.customerInfo.map((x) => {
    let data = { label: x.levelFiveName, value: x.levelFiveId };
    return data;
  });

  function uploadDepartment() {
    props.addInvoice({
      levelFiveId: customer,
      invoice_desc: addDesc,
      invoice_date: Date,
      image: props.uploadImageURL.path,
      data: props.packagingDetail
    },
      handleVisible,
      handleDangerVisible
    );
    setDate("")
    setCustomer("")
    setAddDesc("")
    setPackages(1)
    props.resetReducer();
  }

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  const setCustomerInfo = (selectedOption) => {
    setCustomer(selectedOption.value);
  };

  const onDrop = (pictureFiles, pictureDataURLs) => {
    props.uploadImageApi({
      image: pictureFiles[0],
    });
  };

  const handleChange = (e, index, type) => {
    const list = [...props.packagingDetail];
    if (type == 'description') list[index]['description'] = e.target.value;
    if (type == 'width') list[index]['width'] = e.target.value;
    if (type == 'length') list[index]['length'] = e.target.value;
    if (type == 'width' || type == 'length') list[index]['footage'] = (list[index]['width'] * list[index]['length']) / 144;
    if (type == 'quantity') list[index]['quantity'] = e.target.value;
    if (type == 'width' || type == 'length' || type == 'footage' || type == 'quantity') list[index]['totalFootage'] = list[index]['footage'] * list[index]['quantity'];
    if (type == 'rate') list[index]['rate'] = e.target.value;
    if (type == 'width' || type == 'length' || type == 'footage' || type == 'quantity' || type == 'rate') list[index]['TotalPrice'] = list[index]['totalFootage'] * list[index]['rate'];
    setInputField(list);
  };

  const handleAddInput1 = (e) => {
    let pkVal = isEmpty(e.target.value) ? 1 : e.target.value;
    setPackages(pkVal);
    if (props.packagingDetail.length < pkVal) {
      for (let i = props.packagingDetail.length; i < pkVal; i++) {
        props.handleAddInputInvoiceArray();
      }
    } else if (props.packagingDetail.length > pkVal) {
      for (let i = props.packagingDetail.length; i >= pkVal; i--) {
        props.handleRemoveInputInvoiceArray(i);
      }
    }
  };

  useEffect(() => {
    props.getCustomer();
    props.resetReducer();
  }, []);

  return (
    <>
      {props.isFetchingGetCustomer || props.isSavingInvoice ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingGetCustomer ? 'Loading...' : 'Loading...'}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label><b>Date *</b></Form.Label>
                    <Form.Control
                      type="date"
                      onChange={(e) => setDate(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b>Customer *</b>
                    </Form.Label>
                    <Select
                      placeholder="Select Customer"
                      onChange={setCustomerInfo}
                      options={getCustomer}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label><b>PO # </b></Form.Label>
                    <Form.Control
                      placeholder="PO #"
                      type="text"
                      onChange={(e) => setAddDesc(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="6" xs="6">
                  <Form.Group>
                    <Form.Label>
                      <b>Number of Records *</b>
                    </Form.Label>
                    <Form.Control
                      min={1}
                      type="number"
                      placeholder="Number of Records"
                      name="Number of Records"
                      value={packages}
                      onChange={(e) => handleAddInput1(e)}
                    />
                  </Form.Group>
                </Col>
                <Col lg="6" md="6" xs="6">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <b style={{ fontSize: "large" }}>Upload Image</b>
                    </Form.Label>
                    <ImageUploader
                      withIcon={false}
                      buttonText="Upload Image"
                      onChange={onDrop}
                      imgExtension={[".png"]}
                      maxFileSize={999999999999999999999999}
                      singleImage={true}
                      withPreview={true}
                    />
                  </Form.Group>
                </Col>
                {/* <Col lg="6" md="6" xs="6">
                  <Form.Group className="mb-3" controlId="garana" >
                    <Form.Label><b>Upload Image</b></Form.Label>
                    <input
                      className="form-control"
                      type="file"
                      accept="image/*"
                      name="image"
                      // onChange={onDrop}
                      onBlur={(e) => onDrop(e.target.file)}
                    />
                  </Form.Group>
                </Col> */}
              </Row>

              {props.packagingDetail.map((item, i) => {
                console.log(props.packagingDetail, "sssssssssssssssssss")
                return (
                  <React.Fragment>
                    <Row>
                      <Col lg="1" md="1" xs="1">
                        <Form.Group>
                          <Form.Label>
                            <b>Sr No.</b>
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="number"
                            placeholder="Sr No"
                            name="serial"
                            value={i + 1}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            <b>Description *</b>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Description"
                            name="Description"
                            value={item.description}
                            onChange={e => handleChange(e, i, 'description')}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            <b>Width *</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Width"
                            name="Width"
                            value={item.width}
                            min={0}
                            onChange={e => handleChange(e, i, 'width')} />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            <b>Length *</b>
                          </Form.Label>
                          <Form.Control
                            min={0}
                            type="number"
                            placeholder="Length"
                            name="Length"
                            value={item.length}
                            onChange={e => handleChange(e, i, 'length')} />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            <b>Footage </b>
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="number"
                            placeholder="Footage"
                            name="Footage"
                            value={(Math.round(item.footage * 100) / 100).toFixed(2)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label><b>Quantity *</b></Form.Label>
                          <Form.Control
                            min={0}
                            type="number"
                            placeholder="Quantity"
                            name="Quantity"
                            value={item.quantity}
                            onChange={e => handleChange(e, i, 'quantity')} />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label><b>T.Footage </b></Form.Label>
                          <Form.Control
                            disabled
                            type="number"
                            placeholder="Total Footage"
                            name="Total Footage"
                            value={(Math.round(item.totalFootage * 100) / 100).toFixed(2)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label><b>Rate *</b></Form.Label>
                          <Form.Control
                            min={0}
                            type="number"
                            placeholder="Rate"
                            name="Rate"
                            value={item.rate}
                            onChange={e => handleChange(e, i, 'rate')}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label><b>Total Price</b></Form.Label>
                          <Form.Control
                            style={{ backgroundColor: '#78bbd7', color: "black" }}
                            disabled
                            type="number"
                            placeholder="Total Price"
                            name="TotalPrice"
                            value={(Math.round(item.TotalPrice * 100) / 100).toFixed(2)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </React.Fragment>

                );
              })}

              <div className="sendDiv">
                {isEmpty(Date) || customer == "" || inputField[0].description == "" || inputField[0].quantity == 0 || inputField[0].perPiecePrice == 0 ?
                  <Button
                    className="sendButton"
                    onClick={uploadDepartment}
                    disabled
                    style={{ backgroundColor: 'black' }}
                  >
                    Save
                  </Button> :
                  <Button
                    className="sendButton"
                    onClick={uploadDepartment}

                  >
                    Save
                  </Button>}
              </div>
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addInvoice: (data, handleVisible, handleDangerVisible) => dispatch(addInvoice(data, handleVisible, handleDangerVisible)),
    uploadImageApi: (data) => dispatch(uploadImageApi(data)),
    getCustomer: () => dispatch(getCustomer()),
    handleAddInputInvoiceArray: () => dispatch(handleAddInputInvoiceArray()),
    handleRemoveInputInvoiceArray: (data) => dispatch(handleRemoveInputInvoiceArray(data)),
    setAddInvoiceData: (data) => dispatch(setAddInvoiceData(data)),
    resetReducer: () => dispatch(resetReducer()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingGetCustomer: state.InvoiceReducer.isFetchingGetCustomer,
  customerInfo: state.InvoiceReducer.customerInfo,
  uploadImageURL: state.InvoiceReducer.uploadImageURL,
  isSavingInvoice: state.InvoiceReducer.isSavingInvoice,
  packagingDetail: state.InvoiceReducer.packagingDetail,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoice);
