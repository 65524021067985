import API from "../../../../../global/api";
import { isEmpty, sumBy } from "lodash";
import logo from "../../../../../Images/logo.png";
import jsPDF from "jspdf";
import moment from "moment";
import { baseURL, headers } from "../../../../../global/api";


export const requestGetCustomer = () => {
  return {
    type: "REQUEST_GET_CUSTOMER"
  }
}
export const successGetCustomer = (data) => {
  return {
    type: "SUCCESS_GET_CUSTOMER",
    payload: data,
  }
}
export const errorGetCustomer = () => {
  return {
    type: "ERROR_GET_CUSTOMER"
  }
}
export const getCustomer = () => {
  return (dispatch) => {
    dispatch(requestGetCustomer())
    API.get('/invoice/get_invoice_customer').then((res) => {
      dispatch(successGetCustomer(res.data))
    }).catch((error) => {
      dispatch(errorGetCustomer())
    })
  }
}


export const requestAddInvoice = () => {
  return {
    type: "REQUEST_ADD_INVOICE",
  };
};
export const successAddInvoice = (data) => {
  return {
    type: "SUCCESS_ADD_INVOICE",
    payload: data,
  };
};
export const errorAddInvoice = () => {
  return {
    type: "ERROR_ADD_INVOICE",
  };
};
export const addInvoice = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddInvoice());
    API.post(`/invoice/add_invoice`, data).then((res) => {
      dispatch(getInvoice());
      dispatch(successAddInvoice(data));
      handleVisible();
    }).catch((error) => {
      dispatch(errorAddInvoice());
      handleDangerVisible();
    });
  };
};


export const requestGetInvoice = () => {
  return {
    type: "REQUEST_GET_INVOICE"
  }
}
export const successGetInvoice = (data) => {
  return {
    type: "SUCCESS_GET_INVOICE",
    payload: data,
  }
}
export const errorGetInvoice = () => {
  return {
    type: "ERROR_GET_INVOICE"
  }
}
export const getInvoice = () => {
  return (dispatch) => {
    dispatch(requestGetInvoice())
    API.get('/invoice/get_invoice').then((res) => {
      dispatch(successGetInvoice(res.data))
    }).catch((error) => {
      dispatch(errorGetInvoice())
    })
  }
}

export const requestGetInvoiceReport = () => {
  return {
    type: "REQUEST_GET_INVOICE_REPORT"
  }
}
export const successGetInvoiceReport = (data) => {
  return {
    type: "SUCCESS_GET_INVOICE_REPORT",
    payload: data,
  }
}
export const errorGetInvoiceReport = () => {
  return {
    type: "ERROR_GET_INVOICE_REPORT"
  }
}
export const getInvoiceReport = (data) => {
  return (dispatch) => {
    dispatch(requestGetInvoiceReport())
    API.post('/invoice/get_invoice_report', data).then((res) => {
      if (data.is_pdf == 0) {
        dispatch(successGetInvoiceReport(res.data))
      } else {
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(11);
        const title = "Invoice Report";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;

        const headers = [
          [
            "Invoice Id",
            "Invoice Date",
            "Invoice Description",
            "Invoice Status",
            "Invoice Amount",
            "Customer Name",
          ],
        ];
        const data1 = res.data.data.map((elt, index) => [
          elt.invoice_id,
          elt.invoice_date,
          elt.invoice_desc,
          elt.invoice_status,
          elt.invoice_total_amount,
          elt.level_five.levelFiveName
        ]);
        let content = {
          startY: 160,
          startX: 10,
          head: headers,
          body: data1,
        };
        doc.addImage(logo, "PNG", 40, 15, 100, 100);
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(13);
        doc.setFont("times", "normal");
        doc.text(companyAddress, 150, 60, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(14);
        doc.setFont("times", "bold");
        doc.text(title, 240, 120);
        const grandTotal = "Grand Total: " + sumBy(res.data.data, 'invoice_total_amount')
        doc.text(grandTotal, 40, 140);
        doc.setFont("times", "normal");
        doc.autoTable(content);
        doc.setProperties({ title: `Invoice Report ${moment(new Date()).format("YYYY-MM-DD")}` });
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));

        dispatch(successGetInvoiceReport(res.data.data))
      }
    }).catch((error) => {
      console.log(error)
      dispatch(errorGetInvoiceReport())
    })
  }
}

export const getInvoicePdf = (data) => {
  return (dispatch) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "potrait";
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(11);
    const title = "Invoice Report";
    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
    const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
    const customerName = !isEmpty(data.level_five) ? "Customer Name: " + data.level_five.levelFiveName : "Customer Name: - ";
    const invoiceDate = !isEmpty(data.invoice_date) ? "Invoice Date: " + data.invoice_date : "Invoice Date: - ";
    const grandTotal = "Grand Total: " + data.invoice_total_amount;
    const invoiceId = "Invoice Number: " + data.invoice_id;
    const logo2 = isEmpty(data.image) ? `${baseURL}get_image_data_by_path/upload_files/NoImage.png` : `${baseURL}get_image_data_by_path/${data.image}`

    const headers = [
      [
        "Serial No",
        "Description",
        "Width",
        "length",
        "footage",
        "quantity",
        "totalFootage",
        "rate",
        "TotalPrice",
      ],
    ];
    const data1 = data.invoice_data.map((elt, index) => [
      index + 1,
      elt.description,
      elt.width,
      elt.length,
      elt.footage,
      elt.quantity,
      elt.totalFootage,
      elt.rate,
      elt.TotalPrice,
    ]);
    let content = {
      startY: 220,
      startX: 10,
      head: headers,
      tableWidth: "wrap",
      body: data1,
    };
    doc.addImage(logo, "PNG", 40, 15, 100, 100);
    doc.addImage(logo2, "PNG", 440, 90, 120, 120);
    doc.text(companyName, 150, 40, 0, 20);
    doc.setFontSize(13);
    doc.setFont("times", "normal");
    doc.text(companyAddress, 150, 60, 0, 20);
    doc.text(companyNtn, 150, 80, 0, 20);
    doc.setFontSize(14);
    doc.setFont("times", "bold");
    doc.text(title, 240, 120);
    doc.setFont("times", "normal");
    doc.text(invoiceId, 50, 150, 0, 20);
    doc.text(customerName, 50, 170, 0, 20);
    doc.text(invoiceDate, 50, 190, 0, 20);
    doc.text(`PO #: ${data.invoice_desc}`, 50, 210, 0, 20);
    doc.autoTable(content);
    let finalY = doc.lastAutoTable.finalY;
    doc.text(grandTotal, 420, finalY + 30);
    doc.setProperties({ title: `Invoice Report ${moment(new Date()).format("YYYY-MM-DD")}` });
    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
    // doc.save(`Invoice Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`);
  };
};


export const requestPostInvoice = () => {
  return {
    type: "REQUEST_POST_INVOICE",
  };
};
export const successPostInvoice = (data) => {
  return {
    type: "SUCCESS_POST_INVOICE",
    payload: data,
  };
};
export const errorPostInvoice = () => {
  return {
    type: "ERROR_POST_INVOICE",
  };
};
export const PostInvoice = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestPostInvoice());
    API.post(`/invoice/invoice_post`, data).then((res) => {
      dispatch(getInvoice());
      dispatch(successPostInvoice(res.data));
      //   handleVisible();
    }).catch((error) => {
      dispatch(errorPostInvoice());
      //   handleDangerVisible();
    });
  };
};


export const requestUploadImage = () => {
  return {
    type: "REQUEST_UPLOAD_IMAGE",
  };
};
export const successUploadImage = (data) => {
  return {
    type: "SUCCESS_UPLOAD_IMAGE",
    payload: data,
  };
};
export const errorUploadImage = () => {
  return {
    type: "ERROR_UPLOAD_IMAGE",
  };
};
export const uploadImageApi = (data) => {
  return (dispatch) => {
    const formData = new FormData();
    formData.append("image", data.image)
    dispatch(requestUploadImage());
    API.post(`/invoice/upload_image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      let getData = res.data;
      dispatch(successUploadImage(getData));
    }).catch((error) => {
      dispatch(errorUploadImage());
    })
  }
};


export const handleAddInputInvoiceArray = () => {
  return {
    type: "SET_ADD_INPUT_INVOICE_DATA_ARRAY",
    // payload: data
  };
};
export const handleRemoveInputInvoiceArray = (i) => {
  return {
    type: "SET_REMOVE_INPUT_INVOICE_DATA_ARRAY",
    payload: i,
  };
};
export const setAddInvoiceData = (data) => {
  return {
    type: "SET_ADD_INVOICE_DATA",
    payload: data,
  };
};
export const resetReducer = () => {
  return {
    type: "RESET_REDUCER",
  };
};


export const UpdateRequestInvoice = () => {
  return {
    type: "UPDATE_REQUEST_INVOICE",
  };
};
export const UpdateSuccessInvoice = (data) => {
  return {
    type: "UPDATE_SUCCESS_INVOICE",
    payload: data,
  };
};
export const UpdateErrorInvoice = () => {
  return {
    type: "UPDATE_ERROR_INVOICE",
  };
};
export const updateInvoiceAPI = (data, oldData) => {
  return dispatch => {
    dispatch(UpdateRequestInvoice());
    API.put(`/invoice/invoices_update/${oldData.invoice_data_id}`, {
      description: data.description,
      width: data.width,
      length: data.length,
      footage: data.footage,
      quantity: data.quantity,
      totalFootage: data.totalFootage,
      rate: data.rate,
      TotalPrice: data.TotalPrice,
    }, {
    }).then(res => {
      dispatch(getInvoice());
      dispatch(UpdateSuccessInvoice([data]))
    }).catch((error) =>
      dispatch(UpdateErrorInvoice()));
  }
};


export const DeleteRequestInvoice = () => {
  return {
    type: "DELETE_REQUEST_INVOICE",
  };
};
export const DeleteSuccessInvoice = (data) => {
  return {
    type: "DELETE_SUCCESS_INVOICE",
    payload: data,
  };
};
export const DeleteErrorInvoice = () => {
  return {
    type: "DELETE_ERROR_INVOICE",
  };
};
export const deleteInvoiceAPI = (invoice_id) => {
  return dispatch => {
    dispatch(UpdateRequestInvoice());
    API.delete(`/invoice/invoices_delete/${invoice_id}`).then(res => {
      dispatch(getInvoice());
      dispatch(UpdateSuccessInvoice([res]))
    }).catch((error) =>
      dispatch(UpdateErrorInvoice()));
  }
};