const initialState = {
    isFetchingGetCustomer: false,
    customerInfo: [],
    isSavingInvoice: false,
    addInvoice: [],
    isFetchingInvoice: false,
    invoiceData: [],
    invoiceReportData: [],
    isFetchingInvoiceReport: false,
    isAddingUploadImage: false,
    uploadImageURL: [],
    packagingDetail: [
        {
            description: "",
            width: 0,
            length: 0,
            footage: 0,
            quantity: 0,
            totalFootage: 0,
            rate: 0,
            TotalPrice: 0,
        },
    ],
};


const InvoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUEST_GET_CUSTOMER":
            return { ...state, isFetchingGetCustomer: true };
        case "SUCCESS_GET_CUSTOMER":
            return {
                ...state, isFetchingGetCustomer: false,
                customerInfo: action.payload.result
            };
        case "ERROR_GET_CUSTOMER":
            return { ...state, isFetchingGetCustomer: false };


        case "REQUEST_ADD_INVOICE":
            return { ...state, isSavingInvoice: true };
        case "SUCCESS_ADD_INVOICE":
            return {
                ...state, isSavingInvoice: false,
                addInvoice: action.payload.data
            };
        case "ERROR_ADD_INVOICE":
            return { ...state, isSavingInvoice: false };


        case "REQUEST_GET_INVOICE":
            return { ...state, isFetchingInvoice: true };
        case "SUCCESS_GET_INVOICE":
            return {
                ...state, isFetchingInvoice: false,
                invoiceData: action.payload.result
            };
        case "ERROR_GET_INVOICE":
            return { ...state, isFetchingInvoice: false };


        case "REQUEST_GET_INVOICE_REPORT":
            return { ...state, isFetchingInvoiceReport: true };
        case "SUCCESS_GET_INVOICE_REPORT":
            return {
                ...state,
                invoiceReportData: action.payload,
                isFetchingInvoiceReport: false,
            };
        case "ERROR_GET_INVOICE_REPORT":
            return { ...state, isFetchingInvoiceReport: false };


        case "REQUEST_UPLOAD_IMAGE":
            return { ...state, isAddingUploadImage: true };
        case "SUCCESS_UPLOAD_IMAGE":
            return { ...state, uploadImageURL: action.payload, isAddingUploadImage: false, };
        case "ERROR_UPLOAD_IMAGE":
            return { ...state, isAddingUploadImage: false };



        case "RESET_REDUCER":
            return {
                ...state, packagingDetail: [{
                    description: "",
                    width: 0,
                    length: 0,
                    footage: 0,
                    quantity: 0,
                    totalFootage: 0,
                    rate: 0,
                    TotalPrice: 0,
                }],
            };
        case "SET_ADD_INPUT_INVOICE_DATA_ARRAY":
            return {
                ...state,
                packagingDetail: [...state.packagingDetail, {
                    description: state.packagingDetail[0].description,
                    width: state.packagingDetail[0].width,
                    length: 0,
                    footage: state.packagingDetail[0].footage,
                    quantity: state.packagingDetail[0].quantity,
                    totalFootage: state.packagingDetail[0].totalFootage,
                    rate: state.packagingDetail[0].rate,
                    TotalPrice: state.packagingDetail[0].TotalPrice,
                }],
            };
        case "SET_REMOVE_INPUT_INVOICE_DATA_ARRAY":
            let prevList = [...state.packagingDetail];
            prevList.splice(action.payload, 1);
            return {
                ...state,
                packagingDetail: prevList,
            };

        case "SET_ADD_INVOICE_DATA":
            let list = [...state.packagingDetail];
            list[action.payload.length][action.payload.key] = action.payload.value;
            return {
                ...state,
                packagingDetail: list,
            };





        default:
            return state;
    }
};

export default InvoiceReducer;