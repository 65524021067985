import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import 'jspdf-autotable';
import { filter } from "lodash";
import Modal from "react-bootstrap/Modal";
import SendIcon from '@mui/icons-material/Send';
import Select from 'react-select';
import { PostInvoice, getInvoice, getInvoicePdf, updateInvoiceAPI, deleteInvoiceAPI } from "./InvoiceDetails.actions";
import { getLevel5Data } from "../../accountsLevels/accountsLevel.action";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';


const InvoiceList = (props) => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [cashaccountNo, setCashaccountNo] = useState("");
  const [id, setId] = useState();
  const handleShow = (rowData) => { setId(rowData); setShow(true) };
  const handleClose = () => setShow(false);
  function handlePost() {
    props.PostInvoice({
      levelFiveId: cashaccountNo,
      type: type,
      invoice_id: id,
    })
  }
  const cashAccountNoTop = !isEmpty(props.level5List) && props.level5List
    // .filter((x) => x.levelfiveData.levelFourId == level4Id)
    .map((y) => {
      let data = {
        value: y.levelfiveData.levelFiveId,
        label: y.levelfiveData.allLevelKey + " / " + y.levelfiveData.levelFiveName,
        values: y.levelFour.levelFourId
      };
      return data;
    });
  const handleChangeAccountNo = (selectedOption) => {
    setCashaccountNo(selectedOption.value);
  }
  useEffect(() => {
    props.GetInvoice();
    props.getLevel5Data();
  }, []);
  return (
    <>
      {(props.isFetchingInvoice) ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingwareHouseList ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <MaterialTable
                  title="Invoice Detail"
                  columns={[
                    { title: 'Date', field: 'invoice_date' },
                    { title: 'Customer Name', field: 'level_five.levelFiveName' },
                    { title: 'PO #', field: 'invoice_desc' },
                    { title: 'Invoice Total Value', field: 'invoice_total_amount' },
                    { title: 'Status', field: 'invoice_status' },
                  ]}
                  data={props.invoiceData}
                  detailPanel={(rowData) => {
                    return (
                      <MaterialTable
                        title="Invoice Detail"
                        columns={[
                          { title: "Invoice Description", field: "description" },
                          { title: "Width", field: "width" },
                          { title: "Length", field: "length" },
                          { title: "Footage", field: "footage" },
                          { title: "Quantity", field: "quantity" },
                          { title: "Total Footage", field: "totalFootage" },
                          { title: "Rate", field: "rate" },
                          { title: "Total Price", field: "TotalPrice" },
                        ]}
                        data={rowData.invoice_data}
                        options={{
                          actionsColumnIndex: -1,
                          toolbar: false,
                          exportButton: false,
                          sorting: false,
                          paging: false,
                          pageSize: 50, // make initial page size
                          emptyRowsWhenPaging: false, // To avoid of having empty rows
                          pageSizeOptions: [50, 100, 150, 200],
                          headerStyle: {
                            position: "sticky",
                            top: 0,
                            color: "#00BBBB",
                            fontWeight: "550",
                            onRowAdd: "none",
                          },
                        }}
                        editable={{
                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                              setTimeout(() => {
                                resolve();
                                {
                                  props.updateInvoiceAPI(newData, oldData);
                                }
                              }, 600);
                            }),
                          // onRowDelete: (oldData) =>
                          //     new Promise((resolve) => {
                          //         setTimeout(() => {
                          //             resolve();
                          //             props.deleteCategory(oldData.categoryId);
                          //         }, 600);
                          //     }),
                        }}
                      />
                    );
                  }}
                  options={{
                    actionsColumnIndex: -1,
                    toolbar: true,
                    exportButton: false,
                    sorting: true,
                    paging: true,
                    pageSize: 50,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [50, 100, 150, 200],
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  actions={[
                    (rowData) => {
                      return rowData.invoice_status !== "Pending"
                        ? {
                          icon: SendIcon,
                          disabled: true,
                        }
                        : {
                          icon: SendIcon,
                          disabled: false,
                          onClick: (event, rowData) => {
                            handleShow(rowData.invoice_id)
                            //  props.PostInvoice(rowData.invoice_id);
                          },
                        };
                    },
                    (rowData) => {
                      return isEmpty(rowData)
                        ? {
                          icon: LocalPrintshopIcon,
                          disabled: true,
                        }
                        : {
                          icon: LocalPrintshopIcon,
                          disabled: false,
                          onClick: (event, rowData) => {
                            props.invoicePdf(rowData)
                          },
                        }
                    },
                    (rowData) => {
                      return rowData.invoice_status !== "Pending"
                        ? {
                          icon: DeleteIcon,
                          disabled: true,
                        }
                        : {
                          icon: DeleteIcon,
                          disabled: false,
                          onClick: (event, rowData) => {
                            props.deleteInvoiceAPI(rowData.invoice_id);
                          },
                        };
                    },
                  ]}
                />
              </Form>

            </div>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Select Transection Method</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row >
                  <Col> </Col>
                  <Col> <Button variant="contained" size="large" onClick={() => setType("Bank")}>Bank</Button></Col>
                  <Col> </Col>
                  <Col> <Button variant="contained" size="large" onClick={() => setType("Cash")}>Cash</Button></Col>
                  <Col> </Col>
                </Row>
                <Row>
                  <Col></Col>
                  {type == "" ? "" : type == "Cash" ?
                    <Col xl="8" lg="8" md="8" sm="8" xs="8" style={{ marginTop: "6%" }}>
                      <Form.Group>
                        <Form.Label>
                          <b>Select Cash Account *</b>
                        </Form.Label>
                        <Select
                          placeholder="Select Cash Account"
                          onChange={handleChangeAccountNo}
                          isDisabled={type == "" ? true : false}
                          // options={cashAccountNoTop}
                          options={filter(cashAccountNoTop, x => x.values == 1)}
                        />
                      </Form.Group>
                    </Col> :
                    <Col xl="8" lg="8" md="8" sm="8" xs="8" style={{ marginTop: "6%" }}>
                      <Form.Group>
                        <Form.Label>
                          <b>Select Bank Account *</b>
                        </Form.Label>
                        <Select
                          placeholder="Select Bank Account"
                          onChange={handleChangeAccountNo}
                          isDisabled={type == "" ? true : false}
                          // options={cashAccountNoTop}
                          options={filter(cashAccountNoTop, x => x.values == 2)}
                        />
                      </Form.Group>
                    </Col>}
                  <Col></Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>

                {type == "" || cashaccountNo == "" ? (
                  <Button variant="contained" size="large" disabled >
                    SAVE
                  </Button>
                ) : (
                  <Button variant="contained" size="large" onClick={handlePost}>
                    SAVE
                  </Button>
                )}

              </Modal.Footer>
            </Modal>
          </div>

        </Container>

      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    invoicePdf: (data) => dispatch(getInvoicePdf(data)),
    GetInvoice: () => dispatch(getInvoice()),
    PostInvoice: (data) => dispatch(PostInvoice(data)),
    getLevel5Data: () => dispatch(getLevel5Data()),
    updateInvoiceAPI: (newData, oldData) => dispatch(updateInvoiceAPI(newData, oldData)),
    deleteInvoiceAPI: (invoice_id) => dispatch(deleteInvoiceAPI(invoice_id)),
  };
};

const mapStateToProps = (state, ownProps) => ({
  isFetchingInvoice: state.InvoiceReducer.isFetchingInvoice,
  invoiceData: state.InvoiceReducer.invoiceData,
  level5List: state.accountsLevelReducer.level5List,
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList);