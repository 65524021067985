import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, } from "react-bootstrap";
import "./add-details.css";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { getLevel5Data } from "../accountsLevels/accountsLevel.action";
import { addAccountMasterControl, deleteAccountMasterControl, getAccountMasterControl, updateAccountMasterControl, } from "./AddDetails.actions";
import MaterialTable from "material-table";


const AccountsMasterControl = (props) => {
  const [accountTypeId, setAccountTypeId] = useState("");
  const [accountTypeLabel, setAccountTypeLabel] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Account Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadDemand() {
    props.addAccount(
      {
        levelFiveId: accountTypeId,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const inputEventAccount = (selectedOption, type) => {
    setAccountTypeId(selectedOption.value);
    setAccountTypeLabel(selectedOption.label);
  };
  const cashAccountNo = !isEmpty(props.level5List) &&
    props.level5List.map((x) => {
      let data = {
        value: x.levelfiveData.levelFiveId,
        label:
          x.levelfiveData.allLevelKey + " / " + x.levelfiveData.levelFiveName,
      };
      return data;
    });
  useEffect(() => {
    props.getLevel5Data();
    props.getAccountList();
  }, []);
  return (
    <>
      {props.isFetchinglevel5List ||
        props.isSavingAccountMasterControlList ||
        props.isFetchingAccountMasterControlList ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isSavingAccountMasterControlList ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row style={{ zIndex: 1 }}>
              <Col lg="6" sm="12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Account</Form.Label>
                  <Select
                    // style={{zIndex}}
                    value={{ label: accountTypeLabel }}
                    placeholder="Select Cash Account No..."
                    onChange={inputEventAccount}
                    options={cashAccountNo}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              {accountTypeId == "" ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "black",
                    border: "1px solid black",
                  }}
                >
                  Save
                </Button>
              ) : (
                <Button className="sendButton" onClick={uploadDemand}>
                  Save
                </Button>
              )}
            </div>
            <div className="table">
              <MaterialTable
                style={{ zIndex: 0 }}
                title={"Selected Accounts"}
                columns={[
                  { title: "level Five Id", field: "allLevelKey" },
                  { title: "Account Name", field: "levelFiveName" },
                ]}
                data={props.accountMasterControlList}
                options={{
                  actionsColumnIndex: -1,
                  paging: true,
                  pageSize: 200, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [50, 100, 150, 200],
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
                // components={{
                //   Toolbar: props => (
                //     <div>
                //       <MTableToolbar {...props} />
                //       <div style={{padding: '0px 10px'}}>
                //         <h>Afrax</h1>
                //       </div>
                //     </div>
                //   ),
                // }}
                editable={{
                  // onRowUpdate: (newData, oldData) =>
                  // new Promise((resolve) => {
                  //     setTimeout(() => {
                  //         resolve();
                  //         {
                  //             // console.log('newData', newData),
                  //             props.updateAccount(newData, oldData)
                  //         }
                  //     }, 600);
                  // }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        props.deleteAccount(oldData.levelFiveId);
                      }, 600);
                    }),
                }}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLevel5Data: () => dispatch(getLevel5Data()),
    addAccount: (data, handleVisible, handleDangerVisible) => dispatch(addAccountMasterControl(data, handleVisible, handleDangerVisible)),
    getAccountList: () => dispatch(getAccountMasterControl()),
    deleteAccount: (id) => dispatch(deleteAccountMasterControl(id)),
    updateAccount: (data, oldData) => dispatch(updateAccountMasterControl(data, oldData)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  level5List: state.accountsLevelReducer.level5List,
  isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
  isFetchingAccountMasterControlList: state.AddDataReducer.isFetchingAccountMasterControlList,
  isSavingAccountMasterControlList: state.AddDataReducer.isSavingAccountMasterControlList,
  accountMasterControlList: state.AddDataReducer.accountMasterControlList,
  accountMasterControl: state.AddDataReducer.accountMasterControl,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsMasterControl);
