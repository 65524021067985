import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "./hr-styles.css";
import Select from "react-select";
import ImageUploader from "react-images-upload";
import {
  updateSalary,
  getSalaryById,
  setSalaryFields,
  getShiftControl,
} from "./HRDetails.actions";
import Swal from "sweetalert";

const EditSalary = (props) => {
  !isEmpty(props.salaryListById) &&
    console.log(props.salaryListById[0].salary_transfer === "Cash");

  const [over_time_flag, setOver_time_flag] = useState("");
  const [salary_transfer_flag, setSalary_transfer_flag] = useState("");
  const [late_time_deduct_1_flag, setLate_time_deduct_1_flag] = useState("");
  const [salary_creation_based_flag, setSalary_creation_based_flag] =
    useState("");

  // props.salaryListById[0].salary_transfer
  // console.log('(props.salaryListById[0].salary_transfer === Cash)', props.salaryListById[0].salary_transfer, (props.salaryListById[0].salary_transfer === 'Cash'))

  const { state } = props.location;
  console.log("{ state } = props.location(employeeDataList)", state);

  const oldData = !isEmpty(props.salaryListById) && props.salaryListById[0];
  console.log("props.salaryListById", props.salaryListById);

  console.log("!isEmpty(props.salaryListById)", !isEmpty(props.salaryListById));

  // const [machineId, setMachineId] = useState(!isEmpty(oldData) ? oldData.machine_id : '');
  const [basicSalary, setBasicSalary] = useState(
    !isEmpty(oldData) ? oldData.basic_salary : ""
  );
  const [medicalAllowance, setMedicalAllowance] = useState(
    !isEmpty(props.salaryListById)
      ? props.salaryListById.medicial_allowance
      : ""
  );
  const [petrolAllowance, setPetrolAllowance] = useState(
    !isEmpty(props.salaryListById) ? props.salaryListById.petrol_allowance : ""
  );
  const [specialAllowance, setSpecialAllowance] = useState(
    !isEmpty(props.salaryListById) ? props.salaryListById.other_allowance : ""
  );
  const [accomodationAllowance, setAccomodationAllowance] = useState(
    !isEmpty(props.salaryListById)
      ? props.salaryListById.accomodation_allowance
      : ""
  );
  // const [bonus, setBonus] = useState(!isEmpty(oldData) ? oldData.bonus : '');

  const [totalAllowance, setTotalAllowance] = useState(0);
  const [totalSalary, setTotalSalary] = useState(0);

  // const [overTime, setOverTime] = useState(!isEmpty(oldData) ? oldData.over_time : '');
  // const [overTimeRate, setOverTimeRate] = useState(!isEmpty(oldData) ? oldData.over_time_rate : '');
  // const [salaryTransfer, setSalaryTranfer] = useState(!isEmpty(oldData) ? oldData.salary_transfer : '');
  // const [bankAccount, setBankAccount] = useState(!isEmpty(oldData) ? oldData.bank_account : '');
  // const [lateTimeDeduct_1, setLateTimeDeduct_1] = useState(!isEmpty(oldData) ? oldData.late_time_deduct_1 : '');
  // const [lateTimeDeduct_2, setLateTimeDeduct_2] = useState(!isEmpty(oldData) ? oldData.late_time_deduct_percentage : '');
  // const [exemptionAttendance, setExemptionAttendance] = useState(!isEmpty(oldData) ? oldData.exemption_attendance : '');
  // const [bankName, setBankName] = useState(!isEmpty(oldData) ? oldData.bank_name : '');
  // const [leaveAllowed, setLeaveAllowed] = useState(!isEmpty(oldData) ? oldData.leave_allowed : '');
  // const [activeStatus, setActiveStatus] = useState(!isEmpty(oldData) ? oldData.active_status : '');
  // const [temporaryBlockSalary, setTemporaryBlockSalary] = useState(!isEmpty(oldData) ? oldData.temporary_block_salary : '');
  // const [reasonForBlockSalary, setReasonForBlockSalary] = useState(!isEmpty(oldData) ? oldData.reason_for_block_salary : '');

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  // const [lateTime, setLateTime] = useState(');
  // const [arrivalTime, setArrivalTime] = useState('');
  // const [breakOut, setBreakOut] = useState('');
  // const [breakIn, setBreakIn] = useState('');
  // const [departureTime, setDepartureTime] = useState('');
  // const [allowedLateTime, setAllowedLateTime] = useState('');
  // const [jummaBreakStartTime, setJummaBreakStartTime] = useState('');
  // const [jummaBreakEndTime, setJummaBreakEndTime] = useState('');
  // const [normalDayWorkingHours, setNormalWorkingHours] = useState('');
  // const [jummaWorkingHours, setJummaWorkingHours] = useState('');

  const getOverTimeList = [
    { value: 1, label: "Allowed" },
    { value: 0, label: "Not Allowed" },
  ];
  const getActiveStatusList = [
    { value: 1, label: "Active" },
    { value: 0, label: "Non Active" },
  ];
  // const getLateTimeList = [
  //     { value: "Deduct", label: "Deduct" },
  //     { value: "Not Deduct", label: "Not Deduct" },
  // ];

  const getDeductionList = [
    { value: 1, label: "Deduct" },
    { value: 0, label: "Do not Deduct" },
  ];

  const getSalaryTransferList = [
    { value: "Cash", label: "Cash" },
    { value: "Bank", label: "Bank" },
  ];

  const getBonusList = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  const getexemptionAttendanceList = [
    { value: 1, label: "Allowed" },
    { value: 0, label: "Not Allowed" },
  ];
  const getSalaryCreationList = [
    { value: 1, label: "Allowed" },
    { value: 0, label: "Not Allowed" },
  ];
  const getLeaveAllowedList = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  const getSalaryCreationBasisList = [
    { value: "Shift", label: "Shift" },
    { value: "Master", label: "Master" },
  ];
  const handleShiftSelection = (selectedOption) => {
    props.setSalaryFields({ key: "shift_id", value: selectedOption.value });
  };
  const handleSalaryCreationBasis = (selectedOption) => {
    props.setSalaryFields({
      key: "salary_creation_based",
      value: selectedOption.value,
    });
    setSalary_creation_based_flag(selectedOption.value);
  };

  const handleLeaveAllowed = (selectedOption) => {
    props.setSalaryFields({
      key: "leave_allowed",
      value: selectedOption.value,
    });
  };
  const handleSalaryCreation = (selectedOption) => {
    props.setSalaryFields({
      key: "salary_creation",
      value: selectedOption.value,
    });
  };
  const handleOverTime = (selectedOption) => {
    props.setSalaryFields({ key: "over_time", value: selectedOption.value }); // setOverTimeName(selectedOption.name);
    setOver_time_flag(selectedOption.value);
  };

  // const handleChangeLateTime = (selectedOption) => {
  //     setLateTime(selectedOption.value);
  //     //setLateTimeName(selectedOption.name);
  // };

  const handleChangeActiveStatus = (selectedOption) => {
    props.setSalaryFields({
      key: "active_status",
      value: selectedOption.value,
    });
  };

  const handleLateDeduction = (selectedOption) => {
    props.setSalaryFields({
      key: "late_time_deduct_1",
      value: selectedOption.value,
    });
    // setLate_time_deduct_1_flag(selectedOption.value);
  };

  const handleChangeSalaryTransfer = (selectedOption) => {
    props.setSalaryFields({
      key: "salary_transfer",
      value: selectedOption.value,
    });
    // setSalary_transfer_flag(selectedOption.value);
  };
  const handleChangeBonus = (selectedOption) => {
    props.setSalaryFields({ key: "bonus", value: selectedOption.value });
  };
  const handleChangeexemptionAttendance = (selectedOption) => {
    props.setSalaryFields({
      key: "exemption_attendance",
      value: selectedOption.value,
    });
  };

  const getSelectableShifts =
    !isEmpty(props.shiftControl) &&
    props.shiftControl.map((x) => {
      let data = { value: x.shift_control_id, label: x.shift_name };
      return data;
    });

  const saveSalary = () => {
    console.log(props.salaryListById[0].over_time, props.salaryListById[0].over_time_rate, "ttttttttttttttttttt")
    props.updateSalary(
      {
        // machine_id: isEmpty(machineId) ? props.salaryListById[0].machine_id : machineId,
        machine_id: props.salaryListById[0].machine_id,
        basic_salary: props.salaryListById[0].basic_salary,
        medicial_allowance: props.salaryListById[0].medicial_allowance,
        petrol_allowance: props.salaryListById[0].petrol_allowance,
        other_allowance: props.salaryListById[0].other_allowance,
        accomodation_allowance: props.salaryListById[0].accomodation_allowance,
        special_allowance: props.salaryListById[0].special_allowance,
        total_allowances: (Number(props.salaryListById[0].medicial_allowance) + Number(props.salaryListById[0].petrol_allowance) + Number(props.salaryListById[0].other_allowance) + Number(props.salaryListById[0].special_allowance) + Number(props.salaryListById[0].accomodation_allowance)),
        // bonus: props.salaryListById[0].bonus,            //NotAddedInBackend
        // total_salary: (Number(props.salaryListById[0].medicial_allowance) + Number(props.salaryListById[0].petrol_allowance) + Number(props.salaryListById[0].other_allowance) + Number(props.salaryListById[0].special_allowance) + Number(props.salaryListById[0].accomodation_allowance) + Number(props.salaryListById[0].basic_salary)),
        over_time: props.salaryListById[0].over_time,
        over_time_rate: props.salaryListById[0].over_time == 0 ? 0 : props.salaryListById[0].over_time_rate,
        monthly_mints_allow: props.salaryListById[0].monthly_mints_allow == 0 ? 0 : props.salaryListById[0].monthly_mints_allow,
        // last_time:  lateTime,
        // arrived_time:  arrivalTime,
        // break_in:  breakIn,
        // break_out:  breakOut,
        // depature_time:  departureTime,
        // allowed_late_time:  allowedLateTime,
        // juma_break_start_time:  jummaBreakStartTime,
        // juma_break_end_time:  jummaBreakEndTime,
        // normal_day_working_hours:  normalDayWorkingHours,
        // juma_working_hours:  jummaWorkingHours,
        salary_transfer: props.salaryListById[0].salary_transfer,
        bank_account: props.salaryListById[0].bank_account,
        late_time_deduct_1: props.salaryListById[0].late_time_deduct_1, //TobeAdded
        late_time_deduct_percentage: props.salaryListById[0].late_time_deduct_1 == 0 ? 0 : props.salaryListById[0].late_time_deduct_percentage,
        exemption_attendance: props.salaryListById[0].exemption_attendance,
        bank_name: props.salaryListById[0].bank_name,
        leave_allowed: props.salaryListById[0].leave_allowed,
        salary_creation: props.salaryListById[0].salary_creation,
        salary_creation_based: props.salaryListById[0].salary_creation_based,
        shift_id: props.salaryListById[0].shift_id,
        // active_status: props.salaryListById[0].active_status,
        // temporary_block_salary: props.salaryListById[0].temporary_block_salary,
        // reason_for_block_salary: props.salaryListById[0].reason_for_block_salary,
      },
      oldData.id,
      handleVisible,
      handleDangerVisible
    );
  };
  const setTotals = () => {
    setTotalSalary(
      Number(props.salaryListById[0].medicial_allowance) +
      Number(props.salaryListById[0].petrol_allowance) +
      Number(props.salaryListById[0].special_allowance) +
      Number(props.salaryListById[0].other_allowance) +
      Number(props.salaryListById[0].accomodation_allowance) +
      Number(props.salaryListById[0].basic_salary)
    );

    setTotalAllowance(
      Number(props.salaryListById[0].medicial_allowance) +
      Number(props.salaryListById[0].petrol_allowance) +
      Number(props.salaryListById[0].special_allowance) +
      Number(props.salaryListById[0].other_allowance) +
      Number(props.salaryListById[0].accomodation_allowance)
    );
  };
  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  useEffect(() => {
    // props.getSalaryById(state.employeeId);
    props.getShiftControl();
  }, []);
  return (
    <>
      {props.isFetchingSalaryDataById ||
        isEmpty(props.salaryListById) ||
        isEmpty(oldData) ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="mainHr" onClick={setTotals} onMouseOver={setTotals}>
            <Row>
              <Col lg="4" md="4" xs="4">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Employee Name:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="Employee Name"
                    placeholder="Employee Name...."
                    defaultValue={props.salaryListById[0].employee.name}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col lg="4" md="4" xs="4">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Employee Id:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="Employee Id"
                    placeholder="Employee Id...."
                    defaultValue={props.salaryListById[0].employee.employeeId}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col lg="4" md="4" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Machine Id:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="Machine_id"
                    placeholder="Machine_id...."
                    defaultValue={props.salaryListById[0].machine_id}
                    onChange={(e) => {
                      props.setSalaryFields({
                        key: "machine_id",
                        value: e.target.value,
                      });
                      props.salaryListById[0].machine_id = e.target.value;
                      // setBasicSalary(e.target.value);
                      // console.log(e.target.value);
                      // setTotalSalary(Number(props.salaryListById[0].medicial_allowance) + Number(props.salaryListById[0].petrol_allowance) + Number(props.salaryListById[0].special_allowance) + Number(props.salaryListById[0].other_allowance) + Number(props.salaryListById[0].accomodation_allowance) + Number(props.salaryListById[0].basic_salary));
                      // setTotalAllowance(Number(props.salaryListById[0].medicial_allowance) + Number(props.salaryListById[0].petrol_allowance) + Number(props.salaryListById[0].special_allowance) + Number(props.salaryListById[0].other_allowance) + Number(props.salaryListById[0].accomodation_allowance));
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/*    <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Machine ID:</b></Form.Label>
                                    <Form.Control type="text" id="machine_id" placeholder="Machine Id...."

                                        defaultValue={props.salaryListById[0].machine_id}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "machine_id", value: e.target.value });
                                            console.log('setFieldsConsoleVar', props.setFieldsConsoleVar);


                                            props.salaryListById[0].machine_id = e.target.value;

                                            console.log(e.target.value);
                                        }
                                        }




                                    />
                                </Form.Group>
                            </Col> */}

              <Col lg="4" md="4" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Basic Salary:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="basic_salary"
                    placeholder="Basic Salary...."
                    defaultValue={props.salaryListById[0].basic_salary}
                    onChange={(e) => {
                      props.setSalaryFields({
                        key: "basic_salary",
                        value: e.target.value,
                      });
                      props.salaryListById[0].basic_salary = e.target.value;
                      setBasicSalary(e.target.value);
                      console.log(e.target.value);
                      setTotalSalary(
                        Number(props.salaryListById[0].medicial_allowance) +
                        Number(props.salaryListById[0].petrol_allowance) +
                        Number(props.salaryListById[0].special_allowance) +
                        Number(props.salaryListById[0].other_allowance) +
                        Number(
                          props.salaryListById[0].accomodation_allowance
                        ) +
                        Number(props.salaryListById[0].basic_salary)
                      );
                      setTotalAllowance(
                        Number(props.salaryListById[0].medicial_allowance) +
                        Number(props.salaryListById[0].petrol_allowance) +
                        Number(props.salaryListById[0].special_allowance) +
                        Number(props.salaryListById[0].other_allowance) +
                        Number(props.salaryListById[0].accomodation_allowance)
                      );
                    }}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="4" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Medical Allowance:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="medicial_allowance"
                    placeholder="Medical Allowance...."
                    defaultValue={props.salaryListById[0].medicial_allowance}
                    onChange={(e) => {
                      props.setSalaryFields({
                        key: "medicial_allowance",
                        value: e.target.value,
                      });
                      props.salaryListById[0].medicial_allowance =
                        e.target.value;
                      setTotalSalary(
                        Number(props.salaryListById[0].medicial_allowance) +
                        Number(props.salaryListById[0].petrol_allowance) +
                        Number(props.salaryListById[0].special_allowance) +
                        Number(props.salaryListById[0].other_allowance) +
                        Number(
                          props.salaryListById[0].accomodation_allowance
                        ) +
                        Number(props.salaryListById[0].basic_salary)
                      );
                      setTotalAllowance(
                        Number(props.salaryListById[0].medicial_allowance) +
                        Number(props.salaryListById[0].petrol_allowance) +
                        Number(props.salaryListById[0].special_allowance) +
                        Number(props.salaryListById[0].other_allowance) +
                        Number(props.salaryListById[0].accomodation_allowance)
                      );
                    }}
                  // disabled
                  />
                </Form.Group>
              </Col>

              <Col lg="4" md="4" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Petrol Allowance:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="petrol_allowance"
                    placeholder="Petrol Allowance...."
                    defaultValue={props.salaryListById[0].petrol_allowance}
                    onChange={(e) => {
                      props.setSalaryFields({
                        key: "petrol_allowance",
                        value: e.target.value,
                      });
                      props.salaryListById[0].petrol_allowance = e.target.value;
                      setTotalSalary(
                        Number(props.salaryListById[0].medicial_allowance) +
                        Number(props.salaryListById[0].petrol_allowance) +
                        Number(props.salaryListById[0].special_allowance) +
                        Number(props.salaryListById[0].other_allowance) +
                        Number(
                          props.salaryListById[0].accomodation_allowance
                        ) +
                        Number(props.salaryListById[0].basic_salary)
                      );
                      setTotalAllowance(
                        Number(props.salaryListById[0].medicial_allowance) +
                        Number(props.salaryListById[0].petrol_allowance) +
                        Number(props.salaryListById[0].special_allowance) +
                        Number(props.salaryListById[0].other_allowance) +
                        Number(props.salaryListById[0].accomodation_allowance)
                      );
                    }}
                  // disabled
                  />
                </Form.Group>
              </Col>
              <Col lg="4" md="4" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Other Allowance:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="other_allowance"
                    placeholder="Other Allowance...."
                    defaultValue={props.salaryListById[0].other_allowance}
                    onChange={(e) => {
                      props.setSalaryFields({
                        key: "other_allowance",
                        value: e.target.value,
                      });
                      props.salaryListById[0].other_allowance = e.target.value;
                      setTotalSalary(
                        Number(props.salaryListById[0].medicial_allowance) +
                        Number(props.salaryListById[0].petrol_allowance) +
                        Number(props.salaryListById[0].special_allowance) +
                        Number(props.salaryListById[0].other_allowance) +
                        Number(
                          props.salaryListById[0].accomodation_allowance
                        ) +
                        Number(props.salaryListById[0].basic_salary)
                      );
                      setTotalAllowance(
                        Number(props.salaryListById[0].medicial_allowance) +
                        Number(props.salaryListById[0].petrol_allowance) +
                        Number(props.salaryListById[0].special_allowance) +
                        Number(props.salaryListById[0].other_allowance) +
                        Number(props.salaryListById[0].accomodation_allowance)
                      );
                    }}
                  // disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="4" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Accomodation Allowance:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="accomodation_allowance"
                    placeholder="Accomodation Allowance...."
                    defaultValue={
                      props.salaryListById[0].accomodation_allowance
                    }
                    onChange={(e) => {
                      props.setSalaryFields({
                        key: "accomodation_allowance",
                        value: e.target.value,
                      });
                      props.salaryListById[0].accomodation_allowance =
                        e.target.value;
                      setTotalSalary(
                        Number(props.salaryListById[0].medicial_allowance) +
                        Number(props.salaryListById[0].petrol_allowance) +
                        Number(props.salaryListById[0].special_allowance) +
                        Number(props.salaryListById[0].other_allowance) +
                        Number(
                          props.salaryListById[0].accomodation_allowance
                        ) +
                        Number(props.salaryListById[0].basic_salary)
                      );
                      setTotalAllowance(
                        Number(props.salaryListById[0].medicial_allowance) +
                        Number(props.salaryListById[0].petrol_allowance) +
                        Number(props.salaryListById[0].special_allowance) +
                        Number(props.salaryListById[0].other_allowance) +
                        Number(props.salaryListById[0].accomodation_allowance)
                      );
                    }}
                  // disabled
                  />
                </Form.Group>
              </Col>
              <Col lg="4" md="4" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Special Allowance:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="special Allowance"
                    placeholder="Special Allowance...."
                    defaultValue={props.salaryListById[0].special_allowance}
                    onChange={(e) => {
                      props.setSalaryFields({
                        key: "special_allowance",
                        value: e.target.value,
                      });
                      props.salaryListById[0].special_allowance =
                        e.target.value;
                      setTotalSalary(
                        Number(props.salaryListById[0].medicial_allowance) +
                        Number(props.salaryListById[0].petrol_allowance) +
                        Number(props.salaryListById[0].special_allowance) +
                        Number(props.salaryListById[0].other_allowance) +
                        Number(
                          props.salaryListById[0].accomodation_allowance
                        ) +
                        Number(props.salaryListById[0].basic_salary)
                      );
                      setTotalAllowance(
                        Number(props.salaryListById[0].medicial_allowance) +
                        Number(props.salaryListById[0].petrol_allowance) +
                        Number(props.salaryListById[0].special_allowance) +
                        Number(props.salaryListById[0].other_allowance) +
                        Number(props.salaryListById[0].accomodation_allowance)
                      );
                    }}
                  // disabled
                  />
                </Form.Group>
              </Col>{" "}
              <Col lg="4" md="4" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Total Allowance:</b>
                  </Form.Label>
                  <Form.Control
                    id="totalAllowance"
                    placeholder="Total Allowance...."
                    value={totalAllowance}
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      backgroundColor: 'lightblue',
                    }}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="4" md="4" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Total Salary = (Basic salary + Total Allowance)</b>
                  </Form.Label>
                  <Form.Control
                    id="totalSalary"
                    placeholder="Total Salary..."
                    value={totalSalary}
                    disabled
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      backgroundColor: "gray",
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Over Time:</b>
                  </Form.Label>
                  <Select
                    defaultValue={{
                      label: [
                        props.salaryListById[0].over_time === "1"
                          ? "Allowed"
                          : [
                            props.salaryListById[0].over_time === "0"
                              ? "Not Allowed"
                              : "",
                          ],
                      ],
                    }}
                    placeholder="Select Over Time..."
                    onChange={handleOverTime}
                    options={getOverTimeList}
                  />
                </Form.Group>
              </Col>

              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Over Time Rate:</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="overTimeRate"
                    placeholder="Over Time Rate (%)...."
                    defaultValue={props.salaryListById[0].over_time_rate}
                    onChange={(e) => {
                      props.setSalaryFields({
                        key: "over_time_rate",
                        value: e.target.value,
                      });
                      props.salaryListById[0].over_time_rate = e.target.value;
                    }}
                    disabled={
                      props.salaryListById[0].over_time === "0" ||
                      props.salaryListById[0].over_time === 0
                    }
                  // defaultValue={(!isEmpty(props.salaryListById)) && oldData.over_time_rate}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Late Time Deduction:</b>
                  </Form.Label>
                  <Select
                    defaultValue={{
                      label: [
                        props.salaryListById[0].late_time_deduct_1 === "1"
                          ? "Deduct"
                          : [
                            props.salaryListById[0].late_time_deduct_1 === "0"
                              ? "Do not deduct"
                              : "",
                          ],
                      ],
                    }}
                    placeholder="Late Time Deduction"
                    options={getDeductionList}
                    onChange={handleLateDeduction}
                  />
                </Form.Group>
              </Col>

              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Late Time Deduction:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="lateTimeDeduct_2"
                    placeholder="Late Time Deduct 2...."
                    defaultValue={
                      props.salaryListById[0].late_time_deduct_percentage
                    }
                    onChange={(e) => {
                      props.setSalaryFields({
                        key: "late_time_deduct_percentage",
                        value: e.target.value,
                      });
                      props.salaryListById[0].late_time_deduct_percentage =
                        e.target.value;
                    }}
                    disabled={
                      props.salaryListById[0].late_time_deduct_1 === "0" ||
                      props.salaryListById[0].late_time_deduct_1 === 0
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Bonus:</b>
                  </Form.Label>

                  <Select
                    placeholder="Select Bonus..."
                    defaultValue={{
                      label: props.salaryListById[0].bonus === 1 ? "Yes" : "No",
                    }}
                    onChange={handleChangeBonus}
                    options={getBonusList}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Exemption Attendance:</b>
                  </Form.Label>

                  <Select
                    placeholder="Select Exemption Attendance..."
                    defaultValue={{
                      label: [
                        props.salaryListById[0].exemption_attendance === "1"
                          ? "Allowed"
                          : [
                            props.salaryListById[0].exemption_attendance ===
                              "0"
                              ? "Not Allowed"
                              : "",
                          ],
                      ],
                    }}
                    onChange={handleChangeexemptionAttendance}
                    options={getexemptionAttendanceList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Leave Allowed:</b>
                  </Form.Label>

                  <Select
                    placeholder="Select Leave Allowed..."
                    defaultValue={{
                      label: [
                        props.salaryListById[0].leave_allowed === "1"
                          ? "Yes"
                          : [
                            props.salaryListById[0].leave_allowed === "0"
                              ? "No"
                              : "",
                          ],
                      ],
                    }}
                    onChange={handleLeaveAllowed}
                    options={getLeaveAllowedList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Break Out:</b></Form.Label>
                                    <Form.Control type="text" id="breakout" placeholder="Break Out...." onBlur={(e) => setBreakOut(e.target.value)} 

                                    defaultValue={(!isEmpty(props.salaryListById)) && props.salaryListById[0].break_out}

                                     />
                                </Form.Group>
                            </Col> 
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Break In:</b></Form.Label>
                                    <Form.Control type="text" id="breakIn" placeholder="Break In...." onBlur={(e) => setBreakIn(e.target.value)} 

                                    defaultValue={(!isEmpty(props.salaryListById)) && props.salaryListById[0].break_in}

                                     />
                                </Form.Group>
                            </Col> 
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Departure Time:</b></Form.Label>
                                    <Form.Control type="time" id="departureTime" placeholder="Departure Time...." onBlur={(e) => setDepartureTime(e.target.value)} 

                                    defaultValue={(!isEmpty(props.salaryListById)) && props.salaryListById[0].depature_time}

                                     />
                                </Form.Group>
                            </Col> */}

              {/* <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Allowed Late Time:</b></Form.Label>
                                    <Form.Control type="time" id="allowedLateTime" placeholder="Allowed Late Time...." onBlur={(e) => setAllowedLateTime(e.target.value)} 

                                    defaultValue={(!isEmpty(props.salaryListById)) && props.salaryListById[0].allowed_late_time}

                                     />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Jumma Break Start Time:</b></Form.Label>
                                    <Form.Control type="time" id="jummaBreakStartTime" placeholder="Jumma Break Start Time...." onBlur={(e) => setJummaBreakStartTime(e.target.value)} 

                                    defaultValue={(!isEmpty(props.salaryListById)) && props.salaryListById[0].juma_break_start_time}

                                     />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Jumma Break End Time:</b></Form.Label>
                                    <Form.Control type="time" id="jummaBreakEndTime" placeholder="Jumma Break End Time...." onBlur={(e) => setJummaBreakEndTime(e.target.value)} 

                                    defaultValue={(!isEmpty(props.salaryListById)) && props.salaryListById[0].juma_break_end_time}

                                     />
                                </Form.Group>
                            </Col> */}
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Salary Creation:</b>
                  </Form.Label>
                  <Select
                    // isDisabled={isEmpty(getDepartmentNameList)}+
                    defaultValue={{
                      label: [
                        props.salaryListById[0].salary_creation === 1
                          ? "Allowed"
                          : [
                            props.salaryListById[0].salary_creation === 0
                              ? "Not Allowed"
                              : "",
                          ],
                      ],
                    }}
                    placeholder="Salary Creation..."
                    onChange={handleSalaryCreation}
                    options={getSalaryCreationList}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Salary Transfer:</b>
                  </Form.Label>
                  <Select
                    // isDisabled={isEmpty(getDepartmentNameList)}

                    defaultValue={{
                      label: props.salaryListById[0].salary_transfer,
                    }}
                    placeholder="Select Salary Transfer..."
                    onChange={handleChangeSalaryTransfer}
                    options={getSalaryTransferList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Bank Name:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="bankName"
                    placeholder="Bank Name...."
                    defaultValue={props.salaryListById[0].bank_name}
                    onChange={(e) => {
                      props.setSalaryFields({
                        key: "bank_name",
                        value: e.target.value.toUpperCase(),
                      });
                      props.salaryListById[0].bank_name = e.target.value;
                    }}
                    disabled={
                      // salary_transfer_flag === 'Cash' ||
                      props.salaryListById[0].salary_transfer === "Cash"
                    }
                  />
                  {console.log(
                    props.salaryListById[0].salary_transfer === "Cash"
                  )}
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Bank Account:</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="bankAccount"
                    placeholder="Banking Account...."
                    defaultValue={props.salaryListById[0].bank_account}
                    onChange={(e) => {
                      props.setSalaryFields({
                        key: "bank_account",
                        value: e.target.value,
                      });
                      props.salaryListById[0].bank_account = e.target.value;
                    }}
                    disabled={
                      salary_transfer_flag === "Cash" ||
                      props.salaryListById[0].salary_transfer === "Cash"
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Salary Creation Basis:</b>
                  </Form.Label>
                  <Select
                    // isDisabled={isEmpty(getDepartmentNameList)}
                    defaultValue={{
                      label: props.salaryListById[0].salary_creation_based,
                    }}
                    placeholder="Salary Creation Basis..."
                    onChange={handleSalaryCreationBasis}
                    options={getSalaryCreationBasisList}
                  />
                </Form.Group>
              </Col>

              {props.salaryListById[0].salary_creation_based === "Shift" && (
                <Col lg="4" md="4" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Select Shift:</b>
                    </Form.Label>
                    <Select
                      defaultValue={{
                        label: [
                          props.salaryListById[0].shift_control === null
                            ? ""
                            : props.salaryListById[0].shift_control.shift_name,
                        ],
                      }}
                      // isDisabled={isEmpty(getDepartmentNameList)}
                      placeholder="Select Shift..."
                      onChange={handleShiftSelection}
                      options={getSelectableShifts}
                      isDisabled={
                        props.salaryListById[0].salary_creation_based !==
                        "Shift" || isEmpty(getSelectableShifts)
                      }
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Monthly Allow Minutes</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="Monthly Allow Minutes"
                    placeholder="Monthly Allow Minutes"
                    defaultValue={props.salaryListById[0].monthly_mints_allow}
                    onChange={(e) => {
                      props.setSalaryFields({
                        key: "monthly_mints_allow",
                        value: e.target.value,
                      });
                      props.salaryListById[0].monthly_mints_allow = e.target.value;
                    }}
                    disabled={
                      props.salaryListById[0].monthly_mints_allow === "0" ||
                      props.salaryListById[0].monthly_mints_allow === 0
                    }
                  // defaultValue={(!isEmpty(props.salaryListById)) && oldData.over_time_rate}
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* 
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Active Status:</b></Form.Label>
                                    <Select

                                        defaultValue={{ label: [!isNull(props.salaryListById[0].active_status) ? ([props.salaryListById[0].active_status === true & 'Active'][props.salaryListById[0].active_status === false & 'Non Active']) : ''] }}
                                        //isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Active Status..."
                                        onChange={handleChangeActiveStatus}
                                        options={getActiveStatusList}
                                    />
                                </Form.Group>
                            </Col> */}

            {/* <Row>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Reason For Blocking Salary:</b></Form.Label>
                                    <Form.Control type="text" id="reasonForBlockSalary" placeholder="Reason For Blocking Salary...."
                                        defaultValue={props.salaryListById[0].reason_for_block_salary}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "reason_for_block_salary", value: e.target.value });
                                            props.salaryListById[0].reason_for_block_salary = e.target.value;
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Block Salary Temporarily:</b></Form.Label>
                                    <Form.Control type="text" id="temporaryBlockSalary" placeholder="Block Salary Temporarily...."
                                        defaultValue={props.salaryListById[0].temporary_block_salary}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "temporary_block_salary", value: e.target.value });
                                            props.salaryListById[0].temporary_block_salary = e.target.value;
                                        }}
                                        disabled={isEmpty(props.salaryListById.reason_for_block_salary)} />
                                </Form.Group>
                            </Col>

                        </Row> */}
            {/* <Row>
                             <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Normal Day Working Hours:</b></Form.Label>
                                    <Form.Control type="text" id="normalDayWorkingHours" placeholder="Normal Day Working Hours...." onBlur={(e) => setNormalWorkingHours(e.target.value)} 

                                    defaultValue={(!isEmpty(props.salaryListById)) && props.salaryListById[0].normal_day_working_hours}

                                     />
                                </Form.Group>
                            </Col>

                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Jumma Working Hours:</b></Form.Label>
                                    <Form.Control type="text" id="jummaWorkingHours" placeholder="Jumma Working Hours...." onBlur={(e) => setJummaWorkingHours(e.target.value)} 

                                    defaultValue={(!isEmpty(props.salaryListById)) && props.salaryListById[0].juma_working_hours}

                                     />
                                </Form.Group>
                            </Col> 
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Salary Transfer:</b></Form.Label>
                                    <Select
                                       
                                       defaultValue={{label: (!isEmpty(props.salaryListById)) && props.salaryListById[0].salary[0
                                       ].salary_transfer}}
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        placeholder="Select Salary Transfer..."
                                        onChange={handleChangeSalaryTransfer}
                                        options={getSalaryTransferList}
                                    />
                                </Form.Group>
                            </Col> 
                        </Row>*/}

            {/* <Row>
                            <Col lg="12" md="12" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Bank Account:</b></Form.Label>
                                    <Form.Control type="text" id="bankAccount" placeholder="Banking Account...." onBlur={(e) => setBankAccount(e.target.value)} 

                                    defaultValue={(!isEmpty(props.salaryListById)) && props.salaryListById[0].bank_account}

                                     />
                                </Form.Group>
                            </Col>
                        </Row> */}

            <div className="sendDiv">
              <Button className="sendButton" onClick={() => saveSalary()}>
                Save
              </Button>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSalary: (data, id, handleVisible, handleDangerVisible) =>
      dispatch(updateSalary(data, id, handleVisible, handleDangerVisible)),
    getSalaryById: (id) => dispatch(getSalaryById(id)),
    setSalaryFields: ({ key, value }) =>
      dispatch(setSalaryFields({ key, value })),
    getShiftControl: () => dispatch(getShiftControl()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingSalaryDataById: state.HrReducer.isFetchingSalaryDataById,
  salaryListById: state.HrReducer.salaryListById,
  isUpdatingSalary: state.HrReducer.isUpdatingSalary,
  setFieldsConsoleVar: state.HrReducer.setFieldsConsoleVar,
  shiftControl: state.HrReducer.shiftControl,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSalary);
