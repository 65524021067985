import React, { useEffect, useRef } from "react";
import {
  Container,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import {
  updateVoucherStatus,
  updateVoucherStatus1,
  getAdminVoucher,
  deleteVoucher,
} from "./Voucher.action";
import { reverse } from 'lodash'
import { baseURL, headers } from "../../../../global/api";
import moment from "moment";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';



const VoucherVerification = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  console.log(permissions, "ppppppppppppppppp")
  useEffect(() => {
    //  props.getAdminVoucher();
  }, []);
  // const VoucherData = props.voucherListUser;
  //console.log(reverse(VoucherData), "eeeee")
  const tableRef = useRef();
  function updateTableQuery() {
    tableRef.current.onQueryChange()
  };

  return (
    <>
      {props.isFetchingadminVoucherList ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading...
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              tableRef={tableRef}
              title="Approve Voucher"
              columns={[
                { title: "Voucher Number", field: "voucherManualId" },
                { title: "Account Title", field: "cashAccTittle", },
                { title: "Date", field: "voc_date", render: rowData => moment(rowData.voc_date).format("DD-MM-YYYY") },
                { title: "Status", field: "status" },
                { title: "CR/DR", field: "credit_or_debit", render: rowData => rowData.credit_or_debit == 1 ? "Credit" : rowData.credit_or_debit == 2 ? "Debit" : "" }
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  console.log(query, "qr")
                  let url = `${baseURL}voucher/get_all_voucher_user?`;
                  url += "size=" + query.pageSize;
                  url += "&page=" + (query.page + 1);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: 'GET',
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page - 1,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              detailPanel={(rowData) => {
                return (
                  <MaterialTable
                    title="Voucher Details"
                    columns={[
                      { title: "Account Title", field: "accountTittle" },
                      // { title: "Voucher Date", field: "voucherDate", render: rowData => moment(rowData.voucherDate).format("L") },
                      { title: "Bill No", field: "billNo" },
                      { title: "Voucher Description", field: "voucherDescription" },
                      { title: "Voucher Amount", field: "voucherAmount", render: rowData => rowData.voucherAmount.toLocaleString() },
                      { title: "DR/CR", field: "accountType", render: rowData => rowData.accountType == 1 ? "Credit" : rowData.accountType == 2 ? "Debit" : "" }
                    ]}
                    data={rowData.voucher_data}
                  />
                );
              }}
              // editable={{
              // isEditHidden: (rowData) => Checkpermissions("permissionsSubCatName", "Colour Edit"),
              // isDeleteHidden: (rowData) => permissions.some((p) => p["cat_id"] === 67) ? true : false,
              // onRowUpdate: (newData, oldData) =>
              //   new Promise((resolve) => {
              //     setTimeout(() => {
              //       resolve();
              //       {
              //         props.updateFlavors(newData, oldData);
              //       }
              //     }, 600);
              //   }),
              // onRowDelete: (oldData) =>
              //   new Promise((resolve) => {
              //     setTimeout(() => {
              //       resolve();
              //       props.deleteVoucher(oldData._id);
              //     }, 600);
              //   }),
              // }}
              actions={[
                (rowData) => {
                  return rowData.status !== "Pending"
                    ? {
                      icon: DoneIcon,
                      disabled: true,
                    }
                    : {
                      icon: DoneIcon,
                      disabled: false,
                      onClick: (event, rowData) => {
                        props.updateVoucherStatus(rowData, updateTableQuery);
                      },
                    };
                },
                (rowData) => {
                  return rowData.status !== "Pending"
                    ? {
                      icon: ClearIcon,
                      disabled: true,
                    }
                    : {
                      icon: ClearIcon,
                      disabled: false,
                      onClick: (event, rowData) => {
                        props.updateVoucherStatus1(rowData, updateTableQuery);
                      },
                    };
                },
                (rowData) => {
                  return {
                    icon: DeleteOutlineIcon,
                    hidden: permissions.some((p) => p["cat_id"] == 67) ? false : true,
                    onClick: (event, rowData) => {
                      props.deleteVoucher(rowData.voucherId, updateTableQuery);
                    },
                  }
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                // filtering: true,
                exportButton: true,
                paging: true,
                pageSize: 200, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </div>

        </Container>

      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAdminVoucher: () => dispatch(getAdminVoucher()),
    deleteVoucher: (voucherId) => dispatch(deleteVoucher(voucherId)),
    updateVoucherStatus: (data, updateTableQuery) => dispatch(updateVoucherStatus(data, updateTableQuery)),
    updateVoucherStatus1: (data, updateTableQuery) => dispatch(updateVoucherStatus1(data, updateTableQuery)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  voucherListUser: state.VoucherReducer.voucherListUser,
  isFetchingadminVoucherList: state.VoucherReducer.isFetchingadminVoucherList
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherVerification);
