import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { getLevel5LedgerData } from "../../ledger/Ledger.action";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty, filter } from "lodash";
import Select from "react-select";
import MaterialTable from "material-table";
import { getInvoiceReport, getInvoicePdf } from "./InvoiceDetails.actions";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';


const InvoiceReport = (props) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [item, setItem] = useState("");
  const [itemName, setItemName] = useState("");
  const [id, setId] = useState();
  const [show, setShow] = useState(false);
  const handleShow = (rowData) => { setId(rowData); setShow(true) };
  const handleClose = () => setShow(false);
  const [type, setType] = useState("");

  const getLevel5Customers = filter(props.level5LedgerData, ['levelFourId', 4]).map((x) => {
    let data = {
      value: x.levelFiveId,
      label: x.allLevelKey + " " + x.levelFiveName,
    };
    return data;
  });
  const handleChangeLevel5Ledger = (selectedOption) => {
    setItem(selectedOption.value);
    setItemName(selectedOption.label);
  };

  useEffect(() => {
    props.getLevel5LedgerData();
  }, []);

  function searchData(is_pdf) {
    if (is_pdf == 0) {
      props.getInvoiceReport({
        startedDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        levelFiveId: item,
        is_pdf,
      });
      setShow(true);
    } else {
      props.getInvoiceReport({
        startedDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        levelFiveId: item,
        is_pdf,
      });
    }
  }

  return (
    <>
      {props.isFetchingLevel5LedgerData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingLevel5LedgerData ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Account *</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Account"
                    onChange={handleChangeLevel5Ledger}
                    options={getLevel5Customers}
                  />
                </Form.Group>
              </Col>
            </Row>

            {props.isFetchingLevel5LedgerData ? (
              <div className="loader-div">
                <Button variant="info" disabled>
                  <Spinner
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="info"
                  />
                  {props.isFetchingLevel5LedgerData ? "Loading" : "Loading"}
                </Button>
              </div>
            ) : (
              <Row style={{ marginTop: '15px' }}>
                {/* <Col lg="12" sm="12" className="text-center">
                  <Button className="sendButton" onClick={(e) => searchData(0)} > Search </Button>
                </Col> */}
                <Col lg="12" sm="12" className="text-center">
                  {isEmpty(itemName) ?
                    <Button className="sendButton" disabled style={{ backgroundColor: 'black' }}> Search </Button> :
                    <Button className="sendButton" onClick={(e) => searchData(0)} > Search </Button>
                  }
                </Col>
                <Col lg="12" sm="12" className="text-center">
                  {isEmpty(startDate) || isEmpty(endDate) ?
                    <Button className="sendButton" disabled style={{ backgroundColor: 'black' }}> Genrate PDF</Button> :
                    <Button className="sendButton" onClick={(e) => searchData(1)} > Genrate PDF </Button>
                  }
                </Col>
              </Row>
            )}

            <div className="table">
              {show &&
                <MaterialTable
                  style={{ zIndex: 0 }}
                  title="Invoice Detail"
                  columns={[
                    { title: 'Date', field: 'invoice_date' },
                    { title: 'Customer Name', field: 'level_five.levelFiveName' },
                    { title: 'PO #', field: 'invoice_desc' },
                    { title: 'Invoice Total Value', field: 'invoice_total_amount' },
                    { title: 'Status', field: 'invoice_status' },
                  ]}
                  detailPanel={(rowData) => {
                    return (
                      <MaterialTable
                        title="Invoice Detail"
                        columns={[
                          { title: "Invoice Description", field: "description" },
                          { title: "Width", field: "width" },
                          { title: "Length", field: "length" },
                          { title: "Footage", field: "footage" },
                          { title: "Quantity", field: "quantity" },
                          { title: "Total Footage", field: "totalFootage" },
                          { title: "Rate", field: "rate" },
                          { title: "Total Price", field: "TotalPrice" },
                        ]}
                        data={rowData.invoice_data}
                        options={{
                          actionsColumnIndex: -1,
                          toolbar: false,
                          exportButton: false,
                          sorting: false,
                          paging: false,
                          pageSize: 50,
                          emptyRowsWhenPaging: false,
                          pageSizeOptions: [50, 100, 150, 200],
                          headerStyle: {
                            position: "sticky",
                            top: 0,
                            color: "#00BBBB",
                            fontWeight: "550",
                            onRowAdd: "none",
                          },
                        }}
                      />
                    );
                  }}
                  data={props.invoiceReportData.data}
                  actions={[
                    // (rowData) => {
                    //   return rowData.invoice_status !== "Pending"
                    //     ? {
                    //       icon: SendIcon,
                    //       disabled: true,
                    //     }
                    //     : {
                    //       icon: SendIcon,
                    //       disabled: false,
                    //       onClick: (event, rowData) => {
                    //         handleShow(rowData.invoice_id)
                    //         //  props.PostInvoice(rowData.invoice_id);
                    //       },
                    //     };
                    // },
                    (rowData) => {
                      return isEmpty(rowData)
                        ? {
                          icon: LocalPrintshopIcon,
                          disabled: true,
                        }
                        : {
                          icon: LocalPrintshopIcon,
                          disabled: false,
                          onClick: (event, rowData) => {
                            props.getInvoicePdf(rowData)
                          },
                        }
                    },
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    paging: true,
                    pageSize: 200,
                    emptyRowsWhenPaging: false,
                    pageSizeOptions: [50, 100, 150, 200],
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                />
              }
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInvoicePdf: (data) => dispatch(getInvoicePdf(data)),
    getInvoiceReport: (data) => dispatch(getInvoiceReport(data)),
    getLevel5LedgerData: () => dispatch(getLevel5LedgerData()),
  };
};

const mapStateToProps = (state, ownProps) => ({
  level5LedgerData: state.AccountLegderReducer.level5LedgerData,
  isFetchingLevel5LedgerData: state.AccountLegderReducer.isFetchingLevel5LedgerData,
  invoiceReportData: state.InvoiceReducer.invoiceReportData,
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceReport);
