import React, { useState } from 'react'
import { Container, Form, Row, Col, Button, FormGroup } from 'react-bootstrap';
import { addExpense, getExpense, deleteExpense,getBooking, getMasterControl,/*UpdateHRExpense */ 
UpdateExpence} from './MarqueeDetails.actions';
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { useEffect } from 'react';
import Swal from 'sweetalert';
import { isEmpty, find } from "lodash";
import Select from 'react-select';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
const ExpenseList = (props) => {
   
    const expenseData = isEmpty(props.expenseList) ? [] : props.expenseList.reverse();
   
    useEffect(() => {
        props.getExpense();
    }, []);
    return (
        <>
                <Container fluid>
                    {props.isFetchingExpenseData ? (
                        <div className="loader-div">
                            <Button variant="info" disabled>
                                <Spinner
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    variant="info"
                                />
                                Fetching Expense Data
                            </Button>
                        </div>
                    ) : (


                        <MaterialTable
                            title="Booking Expense"
                            columns={[
                                { title: 'Booking Id', field: 'booking.booking_id' },
                                { title: 'Booking Name', field: 'booking.booking_name' },
                                { title: 'Booking Date', field: 'booking.booking_date' },
                                { title: 'Booking Person', field: 'booking.booking_person' },
                                { title: 'Booking Status', field: 'booking.booking_status' },
                            ]}
                            data={expenseData}
                           
                            options={{
                                actionsColumnIndex: -1,
                                filtering: true,
                                exportButton: true,
                                exportAllData: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [20,50, 100,150, 200],
                                exportPdf: (columns, data) => {
                                    const doc = new jsPDF();
                                    //render:rowData=>rowData.hall_name
                                    const columnTitles =[
                                        { title: 'Bookig Name', field: 'booking.booking_name', render:rowData=>rowData.booking.booking_name},
                                        { title: 'Expense Name', field: 'expense_form_data[0].master_control_item.master_control_item_name',render:rowData=>rowData.expense_form_data[0].master_control_item.master_control_item_name},
                                    ]
                                      .map(columnDef => columnDef.title);
                                      const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                                      const companyAddress=`${process.env.REACT_APP_COMPANY_ADDRESS}`;
                                      const companyNtn=`${process.env.REACT_APP_COMPANY_NTN}`;
                                    const pdfData = data.map((rowData) => [rowData.booking.booking_name,rowData.expense_form_data[0].master_control_item.master_control_item_name]);
                                    let content = {
                                      startY: 50,
                                      startX: 5,
                                      head: [columnTitles],
                                      body: pdfData
                                    };
                                    doc.addImage(logo, 'PNG', 10, 1, 50, 50);
                                    doc.setFontSize(20);
                                    doc.setFont(undefined,'bold')
                                    doc.text(companyName, 50, 22, 0, 0);
                                    doc.setFontSize(13);
                                    doc.setFont('TimesNewRoman','Normal')
                                    doc.text(companyAddress,50, 29, 0, 0);
                                    doc.text(companyNtn,50, 36, 0, 0);
                                    doc.autoTable(content);
                                    doc.save(`Expence Table.pdf`);
                                  },
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },

                            }}
                            detailPanel={(rowData) => {
                                return (
                                    <MaterialTable
                                    // title="Expense Items"
                                    columns={[
                                        { title: 'Product', field: 'product_label' },
                                        { title: 'Description', field: 'expense_form_desc' },
                                        { title: 'Quantity', field: 'expense_form_quantity' },
                                        { title: 'Price', field: 'expense_form_data_price' },
                                        { title: 'Total Price', field: 'expense_form_total_price' },
                                    ]}
                                    data={rowData.expense_form_data}
                                    options={{
                                         actionsColumnIndex: -1,
                                        toolbar: false,

                                        filtering: false,
                                        exportButton: true,
                                        exportAllData: true,
                                        headerStyle: {
                                            position: 'sticky', top: 0,
                                            color: '#00BBBB',
                                            fontWeight: '550',
                                            onRowAdd: 'none',
                                        },

                                    }}
                                    // editable={{
                                    //     onRowUpdate: (newData, oldData) =>
                                    //         new Promise((resolve) => {
                                    //             setTimeout(() => {
                                    //                 resolve();
                                    //                 {
                                    //                     // console.log('newData', newData),
                                    //                     // props.UpdateHRMenu(newData, oldData)
                                    //                 }
                                    //             }, 600);
                                    //         }),
                                    //     onRowDelete: (oldData) =>
                                    //         new Promise((resolve) => {
                                    //             setTimeout(() => {
                                    //                 resolve();
                                    //                 props.deleteMenu(oldData.menu_id);
                                    //                 console.log(oldData, 'old')
                                    //             }, 600);
                                    //         }),
                                    // }}
                                    

                                    />
                                )}}
                        />)
                    }


                </Container>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getExpense: () => dispatch(getExpense()),
        UpdateExpense: (newData, oldData) => dispatch(UpdateExpence(newData, oldData)),
        deleteExpense: (id) => dispatch(deleteExpense(id)),

    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingExpenseData: state.MarqueeReducer.isFetchingExpenseData,
    expenseList: state.MarqueeReducer.expenseList,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpenseList);

